<template>
  <div>
    <img src="@/assets/img/topBG.jpg" alt="" />

    <div class="modul" @click="Selectidentity(item)" v-for="(item, index) in role" :key="index">
      <div>
        <img src="@/assets/img/icon-1.png" alt="" />
        <span class="name">{{ item.user_name }}</span><br />
      </div>
      <div>
        <img src="@/assets/img/icon-2.png" alt="" />
        <span class="school">{{ item.school_name }}</span>
      </div>
    </div>
    <div style="height: 25vw;"></div>
    <div class="btn-f">
      <button class="btn" @click="Logout">退出登录</button>
    </div>
  </div>
</template>

<script>
import { DoLogin, getUserList } from '@/api/login'
import Cookies from "js-cookie";
import { Notify } from "vant";
import {
  setToken,
  logout,
  getOpenId,
  isBindUser,
  getToken, getUserInfo, doLogout, currentUser, setCurrentUser
} from "../../utils/user";
import { isBind } from "../../api/login";

export default {
  data() {
    return {
      role: [],
      radio: "",
    };
  },
  mounted() {
  },
  created() {
    this.role = this.$store.state.role
    if (this.role === null || this.role.length === 0) {
      getUserList({ 'openid': getOpenId() }).then(res => {
        var role = res.data;
        if (role) {
          this.$store.commit("savePath", role);
          this.role = this.$store.state.role
        }
      })
    }
  },
  methods: {
    // 退出登录
    Logout() {
      doLogout()
    },
    // 选择角色
    Selectidentity(row) {
      var userinfo = localStorage.getItem('userinfo');
      // if (!userinfo) {
      //   Notify({ type: "warning", message: "请先授权登录", duration: 2000 });
      //   this.$router.push('/');
      // }
      var info = JSON.parse(userinfo);
      DoLogin({ user_id: row.user_id, openid: info.openid }).then((res) => {

        this.$store.commit('getid', res.data.user_id) // 存id
        this.$store.commit('getname', res.data.user_name) // 用户名
        this.$store.commit('getmobile', res.data.mobile) // 手机号
        this.$store.commit('getphoto', res.data.photo) // 头像
        this.$store.commit('user_type', res.data.user_type)

        var jsonStr = JSON.stringify(res.data);
        setCurrentUser(jsonStr)
        setToken(res.data.token)
        if (res.data.user_type === 'student') {
          this.$router.push('/student_index')
        } else {
          this.$router.push('/teacher_index')
        }
      }).catch(() => {
        Notify({ type: "warning", message: "选择用户失败", duration: 2000 });
      });
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
}

.icon {
  color: #5380ff;
}

/* 选择卡片 */
.modul {
  border-radius: 15px;
  background-color: #f6f8fa;
  width: 85%;
  margin-top: 1rem;
  margin-left: 50%;
  transform: translate(-50%);
  padding: 0.5rem;

  img {
    width: 5%;
    margin-bottom: -0.2rem;
    margin-right: 0.5rem;
  }

  div {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

/* 登录按钮 */
.btn {
  width: 90%;
  height: 3rem;
  margin-top: 3rem;
  border: 0;
  border-radius: 30px;
  color: white;
  background: repeating-linear-gradient(to left, #2357f0, #3db1ea);
}

.btn-f {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  bottom: 4px;
}

.name {
  margin: 0;
  font-weight: bold;
  color: #555555;
  font-size: 3.7vw;
}

.school {
  color: #999999;
}
</style>
